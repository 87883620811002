(function ($) {

// add animate.css class(es) to the elements to be animated
    function setAnimation(_elem, _InOut) {
        // Store all animationend event name in a string.
        // cf animate.css documentation
        var animationEndEvent = 'webkitAnimationEnd mozAnimationEnd MSAnimationEnd oanimationend animationend';

        _elem.each(function () {
            var $elem = $(this);
            var $animationType = 'animated ' + $elem.data('animation-' + _InOut);

            $elem.addClass($animationType).one(animationEndEvent, function () {
                $elem.removeClass($animationType); // remove animate.css Class at the end of the animations
            });
        });
    }

    $(document).ready(function () {
        $(".sidmenu-btn").click(function(){
            $(".sidemenu").toggleClass("open");
            $(".body-wrapper").toggleClass("active");
        });
        $(".sidemenu-close").click(function(){
            $(".sidemenu").toggleClass("open");
            $(".body-wrapper").toggleClass("active");
        });


        $(window).scroll(function(){
            var sticky = $('.header_sticky'),
                scroll = $(window).scrollTop();

            if (scroll >= 100) sticky.addClass('fixed inner-page');
            else sticky.removeClass('fixed inner-page');
        });


        /********************
         * responsive menu
         * *****************/

        $('.main-menu-toggle').click(function () {
            $(this).parent('.main-navigation').children('.main-menu-container-collapse').first().slideToggle('1000');
        });
        /* **
         * Sub Menu
         **/
        $('nav .menu-item-has-children').append('<span class="sub-toggle"> <i class="fa fa-plus"></i> </span>');
        $('nav .page_item_has_children').append('<span class="sub-toggle-children"> <i class="fa fa-plus"></i> </span>');

        $('nav .sub-toggle').click(function () {
            $(this).parent('.menu-item-has-children').children('ul.sub-menu').first().slideToggle('1000');
            $(this).children('.fa-plus').first().toggleClass('fa-minus');
        });

        $('.navbar .sub-toggle-children').click(function () {
            $(this).parent('.page_item_has_children').children('ul.sub-menu').first().slideToggle('1000');
            $(this).children('.fa-plus').first().toggleClass('fa-minus');
        });


        /*main slider*/
        var owl = $('.main_slider');
        owl.owlCarousel({
            loop: true,
            margin: 10,
            autoplay: false,
            autoplayTimeout: 5000,
            nav: true,
            dots: false,
            navText: ["<i class='fas fa-angle-left'></i>", "<i class='fas fa-angle-right'></i>"],
            responsive: {
                0: {
                    items: 1
                },
                600: {
                    items: 1
                },
                1000: {
                    items: 1
                }
            }

        });

        $('.news').owlCarousel({
            loop: true,
            margin: 10,
            autoplay: true,
            autoplayTimeout: 5000,
            nav: true,
            dots: false,
            navText: ["<i class='fas fa-angle-left'></i>", "<i class='fas fa-angle-right'></i>"],
            responsive: {
                0: {
                    items: 1
                },
                600: {
                    items: 2
                },
                1000: {
                    items: 3
                }
            }

        });


        // Fired before current slide change
        owl.on('change.owl.carousel', function (event) {
            var $currentItem = $('.owl-item', owl).eq(event.item.index);
            var $elemsToanim = $currentItem.find("[data-animation-out]");
            setAnimation($elemsToanim, 'out');
        });
        // Fired after current slide has been changed
        var round = 0;
        owl.on('changed.owl.carousel', function (event) {

            var $currentItem = $('.owl-item', owl).eq(event.item.index);
            var $elemsToanim = $currentItem.find("[data-animation-in]");

            setAnimation($elemsToanim, 'in');
        });

    });
    /*$('.matchBanner').matchHeight();*/



})(jQuery);



$(window).on('load', function () {
    $('#exampleModalCenter').modal('show');
});
